import React, { useEffect, useState } from "react";
import "./Messages.css";
import userAvatar from "../../assets/images/userLogo.png";
import botAvatar from "../../assets/images/botLogo.png";
import { Row, Skeleton, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { formatMessage } from "../../utils/message-utils";



const Messages = ({
  sendMessage,
  messages,
  streamMessage,
  chatWindowRef,
  loading
}) => {
  useEffect(() => {
    const scrollToBottom = () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages, streamMessage, chatWindowRef]);


  const renderMessage = (message, index, sender) => {
    const messageId = `message-${index}`;

    return (
      <div key={index} className="flex items-start gap-4 lg:gap-5 ">
        {sender === "User" ? (
          <img
            className="w-[38px] h-[38px] lg:w-[32px] lg:h-[32px]"
            src={userAvatar}
            alt={`user avatar`}
          />
        ) : (
          <img
            className="w-[38px] h-[38px] lg:w-[32px] lg:h-[32px]"
            src={botAvatar}
            alt={`${sender} avatar`}
          />
        )}
        <Row
          gutter={[0, 15]}
          className="w-full ant-row-no-x-margin text-xs sm:text-base pe-[1rem]"
        >
          {message && (
            <>
              <div className="flex flex-col gap-2 text-justify font-poppins">
                <div className="font-medium">
                  {sender == "User" ? "You" : <>FindrAI</>}
                </div>
                <div
                  className={` ${
                    sender === "User"
                      ? " text-textColor"
                      : " max-w-[750px] text-textColor"
                  } text-left`}
                >
                  <div dangerouslySetInnerHTML={{ __html: formatMessage(message) }} />
                </div>
              
              
              </div>
            </>
          )}
        </Row>
      </div>
    );
  };




  return (
    <>
      {messages.length == 0 ? (
        <div className="w-full h-full flex flex-col justify-between items-center">
          <div className="flex flex-col items-center gap-4 grow justify-center">
            <img
              src={botAvatar}
              alt="NYU Bot"
              style={{ width: "44px", height: "44px" }}
            />
            <h1 className="font-poppins text-lg sm:text-xl font-medium">
              How can I help you today?
            </h1>
          </div>
          {/* <div className="card-container flex flex-col gap-4 w-[70%] font-poppins">
            <div className="row flex flex-col md:flex-row gap-4 justify-between">
              <div
                className="w-full md:w-[50%] border border-solid border-menuItemHighlight rounded-xl p-4 shadow-sm cursor-pointer hover:bg-siderBg"
                onClick={() =>
                  handleOptionClick(
                    "Tell me a fun fact about the New York University"
                  )
                }
              >
                <p className="font-medium text-xs sm:text-sm">
                  Tell me a fun fact <br className="hidden sm:inline" />
                  <span className="text-gray font-normal">
                    about the New York University
                  </span>
                </p>
              </div>
              <div
                className="w-full md:w-[50%] border border-solid border-menuItemHighlight rounded-xl p-4 shadow-sm cursor-pointer hover:bg-siderBg"
                onClick={() =>
                  handleOptionClick("How to fill in grades in SRAR?")
                }
              >
                <p className="font-medium text-xs sm:text-sm">
                  How to fill in <br className="hidden sm:inline"/>{" "}
                  <span className="text-gray font-normal">grades in SRAR?</span>
                </p>
              </div>
            </div>
            <div className="row flex flex-col md:flex-row gap-4">
              <div
                className="w-full md:w-[50%] border border-solid border-menuItemHighlight rounded-xl p-4 shadow-sm cursor-pointer hover:bg-siderBg"
                onClick={() =>
                  handleOptionClick("Tell me how to apply for Economics major")
                }
              >
                <p className="font-medium text-xs sm:text-sm">Tell me how to <br className="hidden sm:inline"/>{" "}<span className="text-gray font-normal">
                  apply for Economics major
                </span></p>
                
              </div>
              <div
                className="w-full md:w-[50%] border border-solid border-menuItemHighlight rounded-xl p-4 shadow-sm cursor-pointer hover:bg-siderBg"
                onClick={() =>
                  handleOptionClick("Tell me about department of Anthropology")
                }
              >
                <p className="font-medium text-xs sm:text-sm">Tell me about <br className="hidden sm:inline"/>{" "}<span className="text-gray font-normal">
                  department of Anthropology
                </span></p>
                
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="w-full h-full">
          <div className={`flex flex-col gap-y-10 pb-[1rem] pt-[1rem]`}>
            {messages.map((message, index) =>
              renderMessage(message.msg, index, message.sender)
            )}

            {streamMessage &&
              renderMessage(streamMessage, "stream-message", "bot")}
            {loading && <Skeleton avatar paragraph={{ rows: 3 }} />}
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;

import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useSelector } from "react-redux";

function App() {
  const user = useSelector((state) => state.user.user);

  return (
    <div className="App">
      <Routes>
        {/* {user.accessToken ? ( */}
        <Route path="/" element={<Home />}></Route>

   
      </Routes>
    </div>
  );
}

export default App;

// Imports
import { Layout, message } from "antd";
import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ChatBot.css";
import Messages from "../Messages/Messages";
import InputSection from "../Input/InputSection";
import {
  selectSocketIo,
  initializeSocket,
  disconnectSocket,
} from "../../redux/slices/socketioSlice";
import {
  setCurrentMessage,
  setCurrentResponse,
} from "../../redux/slices/chatbotStatesSlice";
import { setInputLoader } from "../../redux/slices/loaderSlice";


// Imports End

// const socket = io(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
//   transports: ["websocket"], // Ensure WebSocket is used
//   path: "/socket.io", // Default path, adjust if your server uses a different one
// });

export const ChatBot = () => {
  /*  Initialize States */
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState("");
  const [room_id, setRoomId] = useState(null);
  const chatWindowRef = useRef(null);
  const socket = useSelector(selectSocketIo);
  const dispatch = useDispatch();




  /* Socket Initialization and Cleanup */
  useEffect(() => {
    dispatch(initializeSocket());

    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;
    const handleStream = (msg) => {
      if (msg.isFinal) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { msg: streamMessage + msg.data, sender: "bot" },
        ]);

        setStreamMessage("");

        dispatch(
          setCurrentResponse({ currentResponse: streamMessage + msg.data })
        );

        dispatch(setInputLoader({ inputLoader: false }));

     
      } else {

        setStreamMessage((prevStreamMessage) => prevStreamMessage + msg.data);

      }
    };

    socket.on("room_assigned", (data) => {
      setRoomId(data.room_id);
    });

    socket.on("stream", handleStream);

    // Cleanup on component unmount
    return () => {
      socket.off("room_assigned");
      socket.off("stream", handleStream);
    };
  }, [socket, streamMessage]);

  /* Handles SendMessage */
  const sendMessage = (msg) => {
    setLoading(true);
    dispatch(setInputLoader({ inputLoader: true })); // Used to disable the Input

    setMessages((prevMessages) => [
      ...prevMessages,
      { msg: msg, sender: "User" },
    ]);

    socket.emit("start_stream", { query_text: msg });
    dispatch(setCurrentMessage({ currentMessage: msg }));
    setLoading(false);
  };



  return (
    <Layout className="min-h-screen max-h-[20px] relative z-10">
      <>
        <Layout
          className={`flex flex-col w-full md:h-[60%] lg:h-full bg-overlayBg`}
        >
        
          <div className="flex w-full h-[87%] lg:h-full">
            <div
              className={
              
              "chat-container w-[100%] h-[80vh] "
              }
            >
              <div
                ref={chatWindowRef}
                className="h-full overflow-y-auto md:w-[85%] w-[95%] m-auto mt-10"
              >
                <Messages
                  sendMessage={sendMessage}
                  messages={messages}
                  chatWindowRef={chatWindowRef}
                  loading={loading}
                  streamMessage={streamMessage}

                />
              </div>
              <InputSection sendMessage={sendMessage} />
            </div>

          </div>
        </Layout>
      </>
    </Layout>
  );
};
